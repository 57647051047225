import {GET_SINGLE_BIKE, BIKE_LOADING, GET_BIKES} from "../actions/types";

const initialState = {
    error: null,
    isLoaded: false,
    item: {},
    items: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SINGLE_BIKE:
            return {
                ...state,
                item: action.payload,
                isLoaded: false
            };
        case GET_BIKES:
            return {
                ...state,
                items: action.payload,
                isLoaded: false
            };
        case BIKE_LOADING:
            return {
                ...state,
                isLoaded: true
            };
        default:
            return state;
    }
}
