import React from 'react';
import TopMenu from "../partials/TopMenu";
import Footer from "../partials/Footer";
import "./style.scss";
import {connect} from "react-redux";
import {authUser, logout} from "../../actions/AuthActions";
import PropTypes from 'prop-types';
import {getSettings} from "../../actions/SettingsActions";

class BasicLayout extends React.Component {

    static propTypes = {
        authUser: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        getSettings: PropTypes.func.isRequired,
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.getSettings();
        if(this.props.auth.isAuthenticated) {
            this.props.authUser();
        }
    }

    render() {
        return (
            <div>
                <div className="overlay"></div>
                <div className="container">
                    <div className='row'>
                        <div className="col-sm-12 col-12 basic-layout">
                            <TopMenu/>
                            {this.props.children}
                            <Footer/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {authUser, logout, getSettings})(BasicLayout);
