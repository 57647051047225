import React from 'react';
import BasicLayout from '../../layouts/BasicLayout';
import {Link} from "react-router-dom";
import Moment from 'react-moment';
import axios from "axios";
import Parser from "html-react-parser";

export default class SingleNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.location.state,
            attachments: [],
            loaded: false
        }
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            axios
            .get(`${process.env.REACT_APP_API_URL}/messages/${this.props.match.params.id}`)
            .then(res =>
                this.setState({
                    news: res.data,
                    loaded: true
                })
            ).catch(err => console.log(err))
    
            axios.get(`${process.env.REACT_APP_API_URL}/messages/attachments/${this.props.match.params.id}`)
                .then(res => {
                        if (res.data) {
                            this.setState({
                                attachments: res.data
                            })
                        }
                    }
                )
                .catch(err => {
                    console.log(err);
                });
        }
    }


    render() {
        const myState = this.state.news == undefined ? false : this.state.news[0]
        if (this.props.match.params.id && myState) {
            
            const attachments = this.state.attachments.map(attachment => (
                <div>
                    <Link to={`/uploads/${attachment.file_path}`} target="_blank" activeClassName="current">{attachment.file_name}</Link>
                </div>
            ));

            return (
                <BasicLayout>
                    <div className="row">
                        <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-xl-6 offset-xl-3"
                             id="single-news-page">
                            {myState.image ?
                                <img style={imgStyle} src={`/uploads/${myState.image}`} alt={myState.title}/> : null}
                            <h2>{myState.title}</h2>
                            <div>Kategoria: {myState.category}</div>
                            <div>Data rozpoczęcia: <Moment format="YYYY-MM-DD">
                                {myState.dateFrom}
                            </Moment></div>
                            <div>Data zakończenia: <Moment format="YYYY-MM-DD">
                                {myState.dateTo}
                            </Moment></div>
                            <div className='single-news-content'>
                                {myState.content ? <div className="content">{Parser(myState.content)}</div>: null}
                            </div>
                            <div>
                                <div style={{marginTop: '2em'}}>
                                    Załączniki:
                                </div>
                                {attachments}
                            </div>
                            <Link className="btn btn-dark news-return-button" to="/wiadomości">
                                <em className="fas fa-arrow-left"></em>   Wróć do wiadomości
                            </Link>
                        </div>
                    </div>
                </BasicLayout>
            );
        } else {
            if (!this.state.loaded) {
                return (
                    <BasicLayout>
                        <div className="row">
                            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-xl-6 offset-xl-3"
                                 id="single-news-page">
    
                                <h2> Ładowanie komunikatu</h2>
                                
                                <Link className="btn btn-dark news-return-button" to="/wiadomości">
                                    <em className="fas fa-arrow-left"></em>   Wróć do wiadomości
                                </Link>
                            </div>
                        </div>
                    </BasicLayout>
                );
            } else {
                return (
                    <BasicLayout>
                        <div className="row">
                            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-xl-6 offset-xl-3"
                                 id="single-news-page">
    
                                <h2> Brak komunikatu</h2>
                                
                                <Link className="btn btn-dark news-return-button" to="/wiadomości">
                                    <em className="fas fa-arrow-left"></em>   Wróć do wiadomości
                                </Link>
                            </div>
                        </div>
                    </BasicLayout>
                );
            }

        }


    }
}

const imgStyle = {
    borderRadius: '10px',
    boxShadow: '#0000004d 5px 5px 15px',
};
