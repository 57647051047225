import React, {Component} from 'react';
import BasicLayout from "../../layouts/BasicLayout";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from "axios";
import './assets/css/style.scss'
import Citizen from './Citizen';
import Institution from './Institution';

class Owner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
        };
    }

    static propTypes = {
        auth: PropTypes.object,
    };

    componentWillMount() {
        this.loadData();
    }

    loadData() {
        axios.get(`${process.env.REACT_APP_API_URL}/owner`, {
            headers: {
                "Content-type": "application/json",
                "x-auth-token": this.props.auth.token,
            }
        })
            .then(res => {
                    this.setState({
                        user: res.data,
                    });
                }
            )
            .catch(err => {
                console.log(err);
            })
    };

    render() {

        let userContent = null;

        if (this.state.user) {
            if (this.state.user.first_name) {
                userContent = <Citizen user={this.state.user}/>
            } else {
                userContent = <Institution user={this.state.user}/>
            }
        }

        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 height" id="search">
                        <div className="row">
                            {this.state.user ? userContent : null}
                        </div>
                    </div>
                </div>
            </BasicLayout>
        )
    }


}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(Owner);
