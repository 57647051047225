import React, {Component, Fragment} from 'react';
import axios from "axios";
import BasicLayout from "../../layouts/BasicLayout";
import './assets/css/bike-edit-style.scss';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {confirmAlert} from "react-confirm-alert";
import {connect} from "react-redux";
import Notification from "../../partials/Notification";

class BikeEditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.location.state.id,
            description: '',
            usedTypeDrive: null,
            backWheelDiameter: null,
            frontWheelDiameter: null,
            model: null,
            brand: null,

            brandTypes: null,
            modelTypes: null,
            driveTypes: [],
            wheelDiameters: [],
            error: null
        }
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/bike/single/${this.state.id}`, {
            headers: {
                'x-auth-token': this.props.auth.token
            }
        })
            .then(res =>
                this.setState({
                    description: res.data.description,
                    usedTypeDrive: res.data.used_type_drive,
                    backWheelDiameter: res.data.back_wheel_diameter,
                    frontWheelDiameter: res.data.front_wheel_diameter,
                })
            ).catch(err => {
            console.log(err);
        });

        axios.get(`${process.env.REACT_APP_API_URL}/dictionary/wheel-diameters`).then(res => {
                this.setState({
                    wheelDiameters: res.data,
                });
            }
        ).catch(err => {
            console.log(err);
        });

        axios.get(`${process.env.REACT_APP_API_URL}/dictionary/drive-types`).then(res => {
                this.setState({
                    driveTypes: res.data,
                });
            }
        ).catch(err => {
            console.log(err);
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        confirmAlert({
            title: 'Potwierdzenie',
            message: 'Czy potwierdzasz zmianę danych?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => {
                        axios.post(`${process.env.REACT_APP_API_URL}/bike/edit`, {
                            id: this.state.id,
                            description: this.state.description,
                            used_type_drive: this.state.usedTypeDrive,
                            back_wheel_diameter: this.state.backWheelDiameter,
                            front_wheel_diameter: this.state.frontWheelDiameter,
                        }).then(res => {
                                if (res.data.error) {
                                   Notification('Wystąpił błąd podczas zapisu.', 'error');
                                    this.setState({
                                        error: res.data.error,
                                    });
                                } else {

                                   this.props.history.push({
                                       pathname: `/rower/${this.state.id}`,
                                       state: {notificationMsg: 'Zapisano zmiany.'}
                                   });

                                    this.setState({
                                        error: null,
                                    });
                                }
                            }
                        ).catch(err => {
                            console.log(err);
                        });
                    }
                },
                {
                    label: 'Nie',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    cancelEdit = () => {
        this.props.history.push({
            pathname: `/rower/${this.state.id}`,
            state: {isAuthenticated: true}
        });
    };

    render() {
        let driveTypes = this.state.driveTypes;
        let driveTypesItems = driveTypes.map((driveType) =>
            <option value={driveType.id} key={driveType.id}>{driveType.name}</option>
        );

        let wheelDiameters = this.state.wheelDiameters;
        let wheelDiametersItems = wheelDiameters.map((diameter) =>
            <option value={diameter.id} key={diameter.id}>{diameter.name}</option>
        );

        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2" id="search">
                        <div className="row">
                            <div className='container'>
                                <h3 className="text-center">
                                    Edycja roweru
                                </h3>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="owner-background">
                                        <div className='row'>
                                            <div className='col-4 col-md-6 first'>Rodzaj napędu zastosowanego w
                                                rowerze
                                            </div>
                                            <div className='col-8 col-md-6 second'>
                                                <Fragment>
                                                    <select
                                                        name="usedTypeDrive"
                                                        className="form-control"
                                                        value={this.state.usedTypeDrive}
                                                        onChange={this.handleInputChange}
                                                    >
                                                        {driveTypesItems}
                                                    </select>
                                                    {this.state.error ?
                                                        <div
                                                            className='error-label'> {this.state.error.usedTypeDrive} </div> : null}

                                                </Fragment>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className='row'>
                                            <div className='col-4 col-md-6 first'>Średnica przedniego koła roweru</div>
                                            <div className='col-8 col-md-6 second'>

                                                <Fragment>
                                                    <select
                                                        name='frontWheelDiameter'
                                                        className="form-control"
                                                        value={this.state.frontWheelDiameter}
                                                        onChange={this.handleInputChange}
                                                    >
                                                        {wheelDiametersItems}
                                                    </select>
                                                    {this.state.error ?
                                                        <div
                                                            className='error-label'> {this.state.error.frontWheelDiameter} </div> : null}

                                                </Fragment>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className='row'>
                                            <div className='col-4 col-md-6 first'>Średnica tylniego koła roweru</div>
                                            <div className='col-8 col-md-6 second'>
                                                <Fragment>
                                                    <select
                                                        name='backWheelDiameter'
                                                        className="form-control"
                                                        value={this.state.backWheelDiameter}
                                                        onChange={this.handleInputChange}
                                                    >
                                                        {wheelDiametersItems}
                                                    </select>
                                                    {this.state.error ?
                                                        <div
                                                            className='error-label'> {this.state.error.backWheelDiameter} </div> : null}

                                                </Fragment>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className='row'>
                                            <div className='col-4 col-md-6 first'>Opis</div>
                                            <div className='col-8 col-md-6 second'>
                                                <Fragment>
                                                    <input name='description' type='text' className='form-control'
                                                           value={this.state.description}
                                                           onChange={this.handleInputChange}/>

                                                    {this.state.error ?
                                                        <div
                                                            className='error-label'> {this.state.error.description} </div> : null}

                                                </Fragment>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{marginTop: '20px'}} className='col-12 text-center'>
                                        <button type="button" className="btn btn-primary change-data"
                                                onClick={this.cancelEdit}>Wróć bez dokonywania zmian
                                        </button>
                                        <button type="submit" className="btn btn-primary change-data">
                                            Zapisz dane
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </BasicLayout>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(BikeEditForm);
