import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './assets/css/style.scss';
import axios from "axios";
import Parser from "html-react-parser";

/** @property props.apiUrl */

class NewsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: null
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        axios
            .get(`${process.env.REACT_APP_API_URL}${this.props.apiUrl}`)
            .then(res =>
                this.setState({
                    news: res.data
                })
            ).catch(err => console.log(err))
    };

    render() {
        let newsContent = null;

        if (this.state.news) {
            newsContent = this.state.news.map(singleNews => (
                <Link to={{
                    pathname: `/wiadomość/${singleNews.id}`,
                    state: {
                        title: singleNews.title,
                        content: singleNews.content,
                        image: singleNews.mainAttachmentPath,
                        status: singleNews.statusName,
                        category: singleNews.categoryName,
                        dateFrom: singleNews.date_from,
                        dateTo: singleNews.date_to,
                        id: singleNews.id
                    }
                }} className="link">
                <div key={singleNews.id} className="list-news-post">
                    <img src={`/uploads/${singleNews.mainAttachmentPath}`} alt={singleNews.title}/>
                    <h5 className="single-news-title">
                       {singleNews.title}
                    </h5>
                    <div>
                        {singleNews.content ? <div className="content">{Parser(singleNews.content.length > 250 ? singleNews.content.substring(0,250) + '...' : singleNews.content)}</div>: null}
                    </div>
                    <div className='right'>{new Date(singleNews.date_from).toLocaleString()}</div>
                </div>
                </Link>
            ))
        }

        return (
            <div className="row">
                <div className="col-sm-10 offset-2 col-md-8 height">
                    <ReactCSSTransitionGroup
                        transitionName="animation"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={300}>
                        {this.state.news ? newsContent: null}
                    </ReactCSSTransitionGroup>
                </div>
            </div>
        )
    }
}

export default NewsList
