import React, {Component, Fragment} from 'react';
import '../Home/assets/css/style.scss';
import StaticLayout from "../../layouts/StaticLayout";
import axios from "axios";
import Parser from "html-react-parser";

export default class Policy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null
        };
    }

    componentWillMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/settings/static-text?type=policy`)
            .then(res => {
                    this.setState({
                        content: res.data
                    });
                }
            ).catch(err => console.log(err));
    }

    render() {
        return (
            <StaticLayout>
                <div className="offset-sm-2 col-sm-8 col-md-8">
                    <Fragment>
                        {this.state.content ? <div className="content">{Parser(this.state.content)}</div>: null}
                    </Fragment>
                </div>
            </StaticLayout>
        );
    }
}