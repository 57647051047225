import React from 'react';
import './assets/css/style.scss';
import BasicLayout from "../../layouts/BasicLayout";
import axios from "axios";
import { ReCaptcha } from 'react-recaptcha-google'
import {connect} from "react-redux";

class BikeAuthorization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            key: '',
            msg: false,
            isRecaptchaVerified: false,
            isCodeSend: false,
            bikeId: props.location.bikeId
        };

        if(!this.state.bikeId) {
            this.props.history.push("/rower-szukaj");
        }
    }

    handleResetForm = (e) => {
        this.setState({
            isCodeSend: false
        });
    };

    handleButtonSendVerifyCode = (e) => {
        e.preventDefault();

        if( ! this.state.isRecaptchaVerified && parseInt(this.props.settings.recaptchaEnabled) === 1) {
            this.setState({
                msg: 'Musisz wypełnić recaptche'
            });
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/auth/bike-auth-send-key?contact=${this.state.login}`)
                .then(res => {
                    this.setState({
                        isCodeSend: true,
                        msg: null
                    })
                }).catch(err => {
                console.log(err);
                this.setState({
                    msg: err.response.data.msg
                })
            })
       }
    };

    handleButtonLogin = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/auth/bike-auth-verify-key?contact=${this.state.login}&key=${this.state.key}&bikeId=${this.state.bikeId}`)
            .then(res => {
                this.setState({
                    isCodeSend: false,
                    msg: null
                });
                this.props.history.push({
                    pathname: `/rower/${this.state.bikeId}`,
                    state: {isAuthenticated: true}
                });
            }).catch(err => {
            this.setState({
                msg: err.response.data.msg
            })
        })
    };
    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    verifyRecaptchaCallback = (response) => {
        if(response) {
            this.setState({
                isRecaptchaVerified: true,
                msg: null,
            })
        }
    };

    onLoadCallback = (onload) => {
    };

    render() {
        let onSubmit;
        let info;
        let placeholder;
        let button;
        let inputName;
        let formName;
        if (this.state.isCodeSend) {
            onSubmit = this.handleButtonLogin;
            info = 'Wpisz jednorazowy kod dostępu, który przyszedł na Twoją skrzynkę e-mail lub SMS.';
            placeholder = 'kod autoryzujący';
            button = 'Autoryzuj się';
            inputName = 'key';
            formName = 'codeForm';
        } else {
            onSubmit = this.handleButtonSendVerifyCode;
            placeholder = 'nr telefonu lub adres e-mail';
            button = 'Wyślij kod autoryzujący';
            inputName = 'login';
            formName = 'loginForm';
        }
        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2" id="search">
                        <div className="row">
                            <div className="col-10 offset-1 offset-lg-2 col-lg-6">
                                <form name={formName} onSubmit={onSubmit}>
                                    <h3>Weryfikacja</h3>
                                    <div>
                                        {this.state.msg}
                                    </div>
                                    <div className="info">
                                        {info}
                                    </div>
                                    <div className="form-group">
                                        <input name={inputName} type="text" value={this.state[inputName]}
                                               className="form-control input"
                                               onChange={this.handleInputChange}
                                               placeholder={placeholder}/>
                                        <div className='recaptcha'>
                                            {this.props.settings && parseInt(this.props.settings.recaptchaEnabled) === 1 ?
                                                <ReCaptcha
                                                    sitekey={this.props.settings.recaptchaKey}
                                                    size="normal"
                                                    render="explicit"
                                                    verifyCallback={this.verifyRecaptchaCallback}
                                                    onloadCallback={this.onLoadCallback}
                                                    hl={"pl"}
                                                /> : null}
                                        </div>
                                        <div className="search-button">
                                            <button type="submit"
                                                    className="btn btn-secondary col-md-12 d-flex justify-content-center">{button}
                                            </button>
                                        </div>
                                        {this.state.isCodeSend ?
                                            <div onClick={this.handleResetForm} style={{cursor: 'pointer', color: 'red', textAlign: 'right'}}>Wyślij kod ponownie</div> : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </BasicLayout>
        )
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings.settingsList,
});

export default connect(
    mapStateToProps,
    {}
)(BikeAuthorization);
