import React from 'react';
import {Link} from 'react-router-dom';

function MenuBottomItems() {
    return (
     <nav className="navbar navbar-expand-md navbar-footer d-flex justify-content-center">
         <Link className="nav-item nav-link" to="/informacje-o-stronie">O usłudze</Link>
         <Link className="nav-item nav-link" to="/regulamin">Regulamin</Link>
         <Link className="nav-item nav-link" to="/polityka-prywatności">Polityka prywatności</Link>
         <Link className="nav-item nav-link" to="/rodo">Obowiązek informacyjny Rodo</Link>
         <Link className="nav-item nav-link" to="/pomoc">Pomoc</Link>
         {/*<Link className="nav-item nav-link" to="/kontakt">Kontakt</Link>*/}
     </nav>
    )
}

export default MenuBottomItems;
