import axios from 'axios';
import {returnErrors, clearErrors} from './ErrorActions';
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGOUT_SUCCESS,
    LOGIN_SUCCESS,
    AUTH_KEY_SUCCESS,
    AUTH_KEY_RESET,
} from './types';

export const logout = () => (dispatch) => {
    dispatch({
        type: LOGOUT_SUCCESS
    })
};

export const sendVerifyCode = login => (dispatch) => {
    axios.post(`${process.env.REACT_APP_API_URL}/auth/send-verify-key?login=${login}`)
        .then(res => {
            dispatch(clearErrors());
            dispatch({
                    type: AUTH_KEY_SUCCESS,
                    payload: res.data.id
                }
            )
        }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status, "AUTH_ERROR"));
        dispatch({
            type: AUTH_ERROR
        })
    })
};

export const resetVerifyCode = () => (dispatch) => {
    dispatch({
            type: AUTH_KEY_RESET,
        }
    )
};

export const login = (key) => (dispatch, getState) => {
    axios.post(`${process.env.REACT_APP_API_URL}/auth/login?id=${getState().auth.user}&key=${key}`)
        .then(res => {
            dispatch(clearErrors());
            return dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data
                }
            );
        }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status, "AUTH_ERROR"));
        dispatch({
            type: AUTH_ERROR
        })
    })
};

export const authUser = () => (dispatch, getState) => {
    dispatch({type: USER_LOADING});
    axios.get(`${process.env.REACT_APP_API_URL}/auth`, tokenConfig(getState))
        .then(res => {
                dispatch({
                    type: USER_LOADED,
                    payload: res.data
                })
            }
        )
        .catch(err => {
            dispatch({
                type: AUTH_ERROR
            })
        })
};

export const tokenConfig = getState => {
    const token = getState().auth.token;
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    };
    if (token) {
        config.headers['x-auth-token'] = token;
    }

    return config
};
