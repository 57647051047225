import React from 'react';
import NewsList from '../../partials/NewsList';
import BasicLayout from '../../layouts/BasicLayout';

function News() {
    return (
        <BasicLayout>
            <NewsList apiUrl={'/messages'} />
        </BasicLayout>
    );
}

export default News;