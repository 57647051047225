import React from 'react';
import '../../pages/Home/assets/css/style.scss';
import BasicLayout from "../../layouts/BasicLayout";
import BikeForm from "../../partials/BikeForm/BikeForm";

export default function BikeSearch() {
    return (
        <BasicLayout>
            <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2" id="bike-search-results">
                    <div className="row">
                        <div className="col-10 offset-1 offset-xl-3 col-xl-6 bike-search">
                            <h3>Wyszukiwanie roweru</h3>
                          <BikeForm/>
                        </div>
                    </div>
                </div>
            </div>
        </BasicLayout>
    )
}
