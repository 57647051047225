import React from 'react';
import './assets/css/style.scss';
import BasicLayout from "../../layouts/BasicLayout";
import {sendVerifyCode, login, resetVerifyCode} from "../../../actions/AuthActions";
import {clearErrors} from "../../../actions/ErrorActions";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {ReCaptcha} from 'react-recaptcha-google'

class Login extends React.Component {
    state = {
        login: '',
        key: '',
        msg: null,
        isRecaptchaVerified: false,
    };

    static propTypes = {
        error: PropTypes.object.isRequired,
        clearErrors: PropTypes.func.isRequired,
        sendVerifyCode: PropTypes.func.isRequired,
        resetVerifyCode: PropTypes.func.isRequired,
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool
    };

    componentDidMount() {
        this.props.clearErrors();
    }

    componentDidUpdate(prevProps) {
        const {error} = this.props;
        if (error !== prevProps.error) {
            if (error.id === 'AUTH_ERROR') {
                this.setState({msg: error.msg.msg})
            } else {
                this.setState({
                    msg: null
                })
            }
        }
        if (this.props.isAuthenticated) {
            this.props.history.push("/strona-główna");
        }
    }

    handleButtonSendVerifyCode = (e) => {
        e.preventDefault();

        if (!this.props.settings.recaptchaEnabled || (!this.state.isRecaptchaVerified && parseInt(this.props.settings.recaptchaEnabled)) === 1) {
            this.setState({
                msg: 'Musisz wypełnić recaptche'
            });
        } else {
            this.props.sendVerifyCode(this.state.login)
        }
    };

    handleButtonLogin = (e) => {
        e.preventDefault();
        this.props.login(this.state.key)
    };
    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleResetForm = (e) => {
        this.props.resetVerifyCode()
    };

    verifyRecaptchaCallback = (response) => {
        if (response) {
            this.setState({
                isRecaptchaVerified: true,
                msg: null,
            })
        }
    };

    onLoadCallback = (onload) => {
    };

    render() {
        let onSubmit;
        let info;
        let placeholder;
        let button;
        let inputName;
        let formName;
        if (this.props.isCodeSend) {
            onSubmit = this.handleButtonLogin;
            info = 'Wpisz jednorazowy kod dostępu, który przyszedł na Twoją skrzynkę e-mail lub SMS.';
            placeholder = 'kod autoryzujący';
            button = 'Zaloguj się';
            inputName = 'key';
            formName = 'codeForm';
        } else {
            onSubmit = this.handleButtonSendVerifyCode;
            placeholder = 'nr telefonu lub adres e-mail';
            button = 'Wyślij kod autoryzujący';
            inputName = 'login';
            formName = 'loginForm';
        }
        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2" id="search">
                        <div className="row">
                            <div className="col-10 offset-1 offset-lg-3 col-lg-6 login">
                                <form name={formName} onSubmit={onSubmit}>
                                    <h3>Weryfikacja właściciela</h3>
                                    <div>
                                        {this.state.msg}
                                    </div>
                                    <div className="info">
                                        {info}
                                    </div>
                                    <div className="form-group">
                                        <input name={inputName} autoFocus type="text" value={this.state[inputName]}
                                               className="form-control input"
                                               onChange={this.handleInputChange}
                                               placeholder={placeholder}/>
                                        <div className="search-button">
                                            <button type="submit"
                                                    className="btn btn-secondary col-md-12 d-flex justify-content-center">{button}
                                            </button>
                                        </div>

                                        {this.props.isCodeSend ?
                                            <div onClick={this.handleResetForm} style={{cursor: 'pointer', color: 'red', textAlign: 'right'}}>Wyślij kod
                                                ponownie</div> : null}

                                        <div className='recaptcha'>
                                            {this.props.settings && this.props.settings.recaptchaKey && parseInt(this.props.settings.recaptchaEnabled) === 1 ?
                                                <ReCaptcha
                                                    sitekey={this.props.settings.recaptchaKey}
                                                    size="normal"
                                                    render="explicit"
                                                    verifyCallback={this.verifyRecaptchaCallback}
                                                    onloadCallback={this.onLoadCallback}
                                                    hl={"pl"}
                                                /> : null}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </BasicLayout>
        )
    }
}

const mapStateToProps = state => ({
    error: state.error,
    isCodeSend: state.auth.isCodeSend,
    settings: state.settings.settingsList,
    isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, {sendVerifyCode, clearErrors, login, resetVerifyCode})(Login);
