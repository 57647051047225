import {GET_SINGLE_BIKE, BIKE_LOADING, GET_BIKES} from "../actions/types";
import axios from 'axios'
import {tokenConfig} from './AuthActions';

export const getSingleBike = (type, number) => dispatch => {
    dispatch(setBikeLoading());
    axios
        .get(
            `${process.env.REACT_APP_API_URL}/bike/single?type=${type}&number=${number}`
        )
        .then(res =>
                dispatch({
                    type: GET_SINGLE_BIKE,
                    payload: res.data
                }), err => {
                console.log(err)
            }
        )
};

export const getSingleBikeById = id => (dispatch, getState) => {
    dispatch(setBikeLoading());
    axios.get(`${process.env.REACT_APP_API_URL}/bike/single/${id}`, tokenConfig(getState))
        .then(res =>
                dispatch({
                    type: GET_SINGLE_BIKE,
                    payload: res.data
                }), err => {
                console.log(err)
            }
        )
};

export const getBikeList = () => (dispatch, getState) => {
    dispatch(setBikeLoading());

    axios
        .get(
            `${process.env.REACT_APP_API_URL}/bike/list`, tokenConfig(getState)
        )
        .then(res => {
                dispatch({
                    type: GET_BIKES,
                    payload: res.data
                })
            }
            , err => {
                console.log(err)
            }
        )
};

export const setBikeLoading = () => {
    return {
        type: BIKE_LOADING
    }
};


