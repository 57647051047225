import React, {Component, Fragment} from 'react';
import MenuBottomItems from './MenuBottomItems';
import EuropeanFundsImage from './assets/images/fundusze-europejskie2.png';
import ProvincialGovernment from './assets/images/samorzad-wojewodztwa-wielkopolskiego.png';
import EuropeanUnionImage from './assets/images/unia-europejska2.png';
import axios from "axios";
import Parser from "html-react-parser";
import ContactForm from "./ContactForm";
import Wcag from "./Wcag";
import IeNotSupported from "./IeNotSupported";

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
        };
    }

    componentWillMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/settings/static-text?type=footer`)
            .then(res => {
                    this.setState({
                        content: res.data
                    });
                }
            ).catch(err => console.log(err));
    }

    render() {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        return (
            <footer>
                <div className="container">
                    <MenuBottomItems/>
                    <div className="row">
                        <ContactForm/>
                        <Wcag/>
                        <div className="col footer-logos text-center justify-content-center">
                                <img src={EuropeanFundsImage} alt="Fundusze europejskie"/>
                                <img src={ProvincialGovernment} alt="Samorząd województwa wielkopolskiego"/>
                                <img src={EuropeanUnionImage} alt="Unia Europejska"/>
                        </div>
                        <div className="w-100"></div>
                        <div className="col-sm-8 offset-sm-2 text-center">
                            <Fragment>
                                {this.state.content ?
                                    <div className="content">{Parser(this.state.content)}</div> : null}
                            </Fragment>
                        </div>
                    </div>
                    {isIE ? <IeNotSupported/> : null}
                </div>
            </footer>
        )
    }
}
