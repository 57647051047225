import React, {Component, Fragment} from "react";
import BasicLayout from "../../layouts/BasicLayout";
import axios from "axios";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Select from 'react-select';

class BikeRegisterNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            serialNumber: null,
            manufacturingNumber: null,
            number: null,
            value: null,
            policeStation: null,
            usedTypeDrive: null,
            backWheelDiameter: null,
            frontWheelDiameter: null,
            brand: null,
            model: null,
            registerCity: null,
            note: null,

            policeStations: [],
            brandTypes: [],
            modelTypes: [],
            driveTypes: [],
            wheelDiameters: [],
            error: null,
            registered: false
        }
    }


    static propTypes = {
        auth: PropTypes.object,
    };

    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleInputBrandChange = (e) => {
        //this.getModelsByBrand(e.value);
        this.setState({
            brand: e.value
        })
    };

    getModelsByBrand(id) {
        axios.get(`${process.env.REACT_APP_API_URL}/dictionary/model-types/${id}`).then(res => {
                this.setState({
                    model: res.data[0].id,
                    modelTypes: res.data,
                });
            }
        ).catch(err => {
            console.log(err);
        });
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/dictionary/wheel-diameters`).then(res => {
                this.setState({
                    backWheelDiameter: res.data[0].id,
                    frontWheelDiameter: res.data[0].id,
                    wheelDiameters: res.data,
                });
            }
        ).catch(err => {
            console.log(err);
        });

        axios.get(`${process.env.REACT_APP_API_URL}/dictionary/drive-types`).then(res => {
                this.setState({
                    usedTypeDrive: res.data[0].id,
                    driveTypes: res.data,
                });
            }
        ).catch(err => {
            console.log(err);
        });

        axios.get(`${process.env.REACT_APP_API_URL}/dictionary/brand-types`).then(res => {
                this.getModelsByBrand(res.data[0].id);
                this.setState({
                    //brand: res.data[0].id,
                    brandTypes: res.data,
                });
            }
        ).catch(err => {
            console.log(err);
        });

        axios.get(`${process.env.REACT_APP_API_URL}/dictionary/police-stations`).then(res => {
                this.setState({
                    policeStation: res.data[0].id,
                    policeStations: res.data,
                });
            }
        ).catch(err => {
            console.log(err);
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${process.env.REACT_APP_API_URL}/bike/register`,
            {
                bike: {
                    description: this.state.description,
                    serialNumber: this.state.serialNumber,
                    manufacturingNumber: this.state.manufacturingNumber,
                    number: this.state.number,
                    value: this.state.value,
                    policeStation: this.state.policeStation,
                    usedTypeDrive: this.state.usedTypeDrive,
                    backWheelDiameter: this.state.backWheelDiameter,
                    frontWheelDiameter: this.state.frontWheelDiameter,
                    brand: this.state.brand,
                    model: this.state.model,
                    registerCity: this.state.registerCity,
                    note: this.state.note,
                },
            },
            {
                headers: {
                    "x-auth-token": this.props.auth.token,
                }
            }
        ).then(res => {
                if (res.data.error) {
                    this.setState({
                        error: res.data.error,
                    });
                } else {
                    this.setState({
                        registered: true,
                    });
                }
            }
        ).catch(err => {
            console.log(err);
        });

    };

    render() {

        let driveTypes = this.state.driveTypes;
        let driveTypesItems = driveTypes.map((driveType) =>
            <option value={driveType.id} key={driveType.id}>{driveType.name}</option>
        );

        let wheelDiameters = this.state.wheelDiameters;
        let wheelDiametersItems = wheelDiameters.map((diameter) =>
            <option value={diameter.id} key={diameter.id}>{diameter.name}</option>
        );

        let brandTypes = this.state.brandTypes;
        let brandTypesItems = brandTypes.map((brand) =>
                ({label: brand.name, value: brand.id})
        );

        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2" id="search">
                        <h3 className="text-center">
                            Rejestracja roweru
                        </h3>
                        {this.state.registered
                            ?
                            <div>
                                <p> Rower został wstępnie zarejestrowany. Zgłoś się do najbliższej jednostki Policji w
                                    celu dokończenia rejestracji i wygenerowania graweru.</p>
                            </div>
                            :
                            <div className='owner-background'>
                                <form onSubmit={this.handleSubmit}>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Numer seryjny:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                                <input name='serialNumber' type='text' className='form-control'
                                                       onChange={this.handleInputChange}/>
                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.serial_number} </div> : null}

                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Numer fabryczny:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                                <input name='manufacturingNumber' type='text' className='form-control'
                                                       onChange={this.handleInputChange}/>

                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.manufacturing_number} </div> : null}

                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Inny numer:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                                <input name='number' type='text' className='form-control'
                                                       onChange={this.handleInputChange}/>

                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.custom_number} </div> : null}

                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Marka roweru:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                                <Select options={brandTypesItems} onChange={this.handleInputBrandChange}
                                                        placeholder={'Wyszukaj markę'}/>
                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.brand_id} </div> : null}

                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Rodzaj napędu:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                                <select
                                                    name='usedTypeDrive'
                                                    className="form-control"
                                                    onChange={this.handleInputChange}
                                                >
                                                    {driveTypesItems}
                                                </select>
                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.used_type_drive} </div> : null}

                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Średnica tylnego koła:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                                <select
                                                    name='backWheelDiameter'
                                                    className="form-control"
                                                    onChange={this.handleInputChange}
                                                >
                                                    {wheelDiametersItems}
                                                </select>
                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.back_wheel_diameter} </div> : null}

                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Średnica przedniego koła:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                                <select
                                                    name='frontWheelDiameter'
                                                    className="form-control"
                                                    onChange={this.handleInputChange}
                                                >
                                                    {wheelDiametersItems}
                                                </select>
                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.front_wheel_diameter} </div> : null}

                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Wartość roweru:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                                <input name='value' type='text' className='form-control'
                                                       onChange={this.handleInputChange}/>

                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.value} </div> : null}
                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Miejscowość rejestracji:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                                <input name='registerCity' type='text' className='form-control'
                                                       onChange={this.handleInputChange}/>

                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.register_city} </div> : null}
                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 col-md-6'>Opis obiektu:</div>
                                        <div className='col-8 col-md-6'>
                                            <Fragment>
                                            <textarea rows='5' name='description' type='text' className='form-control'
                                                      onChange={this.handleInputChange}/>

                                                {this.state.error ?
                                                    <div
                                                        className='error-label'> {this.state.error.description} </div> : null}
                                            </Fragment>
                                        </div>
                                    </div>
                                    <div className='col-12 text-center' style={{marginTop: '20px'}}>
                                        <button type="submit" className="btn btn-primary change-data">REJESTRUJ ROWER
                                        </button>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </BasicLayout>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(BikeRegisterNew);
