import React, {Fragment, Component} from 'react';
import {logout} from  '../../actions/AuthActions';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

class MenuItems extends Component{
    static propTypes = {
        logout: PropTypes.func.isRequired
    };
    render() {
        const {isAuthenticated} = this.props.auth;
        const questLinks = (
            <Fragment>
                <Link className="nav-item nav-link" to="/zaloguj">
                    <em className="fas fa-sign-in-alt"></em> Zaloguj się
                </Link>
            </Fragment>
        );

        const authLinks = (
            <Fragment>
                <Link className="nav-item nav-link" to="/lista-rowerów">
                    <em className="fas fa-list-ol"></em> Moje rowery
                </Link>
                <Link className="nav-item nav-link" to="/właściciel">
                    <em className="fas fa-database"></em> Moje dane
                </Link>
                <Link className="nav-item nav-link" to="/rower-rejestracja">
                    <em className="fas fa-plus"></em> Zarejestruj rower
                </Link>
                <Link className="nav-item nav-link" onClick={this.props.logout} to="/strona-główna">
                    <em className="fas fa-sign-in-alt"></em> Wyloguj się
                </Link>
            </Fragment>
        );

        return (
            <div className="navbar" style={{marginRight: '20px'}}>
                <Link to="/strona-główna" className="nav-item nav-link">
                    <em className="fas fa-home"></em> Start
                </Link>
                <Link className="nav-item nav-link" to="/rower-szukaj">
                    <em className="fas fa-bicycle"></em> Sprawdź rower
                </Link>
                <Link className="nav-item nav-link" to="/wiadomości">
                    <em className="fas fa-home"></em> Wiadomości
                </Link>
                {isAuthenticated ? authLinks : questLinks}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps,{logout})(MenuItems)
