import React, {Component} from "react";

export default class Wcag extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contrast: false,
            a2: false,
            a3: false,
        };
    }


    setContrast = (e) => {
        e.preventDefault();
        if (this.state.contrast) {
            this.setState({
                contrast: false
            });
            document.body.classList.remove('wcag-contrast');
        } else {
            this.setState({
                contrast: true
            });
            document.body.classList.add('wcag-contrast');
        }
    };

    setSizeA1 = (e) => {
        e.preventDefault();
        document.body.classList.remove('wcag-a2');
        document.body.classList.remove('wcag-a3');
    };

    setSizeA2 = (e) => {
        e.preventDefault();
        document.body.classList.add('wcag-a2');
        document.body.classList.remove('wcag-a3');
    };

    setSizeA3 = (e) => {
        e.preventDefault();
        document.body.classList.add('wcag-a3');
        document.body.classList.remove('wcag-a2');
    };

    render() {
        return (
            <div className="wcag">
                <div onClick={this.setContrast}>
                    <i className="fa fa-adjust" aria-hidden="true"></i>
                </div>
                <div onClick={this.setSizeA1}>
                    A
                </div>
                <div onClick={this.setSizeA2}>
                    A+
                </div>
                <div onClick={this.setSizeA3}>
                    A++
                </div>
            </div>
        )
    }
}
