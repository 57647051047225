import React from 'react';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export default function Notification(message, status) {

    if(!message)  return;

    if(status === 'success') NotificationManager.success(message, '');
    else if(status === 'info') NotificationManager.info(message, '');
    else NotificationManager.error(message, '');

    return (<div>
    </div>)
}
