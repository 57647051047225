import React, {Component, Fragment} from "react";
import BasicLayout from "../../layouts/BasicLayout";
import axios from "axios";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import './assets/css/style.scss';

class BikeRegisterEngraving extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bike: [],
            bikeId: null,
            number: null,
            type: 1,
            found: null,
            registered: false,
            error: null,
        }
    }

    static propTypes = {
        auth: PropTypes.object,
    };

    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    bikeImagePath = (bike) => {
        let path;
        if(!bike.attachments) return null;
        if (bike.attachments[0]) {
            path = '/uploads/' + bike.attachments[0].file_path
        }

        return path;
    };

    handleFindBike = (e) => {
        axios.get(`${process.env.REACT_APP_API_URL}/bike/search?type=${this.state.type}&number=${this.state.number}`).then(res => {
                if (!!res.data.id) {
                    axios.get(`${process.env.REACT_APP_API_URL}/bike/single-engraving/${res.data.id}`, {
                        headers: {
                            "x-auth-token": this.props.auth.token,
                        }
                    }).then(resBike => {
                            if (resBike.data.error) {
                                this.setState({
                                    error: resBike.data.error,
                                });
                            } else {
                                this.setState({
                                    bike: resBike.data,
                                    found: !!res.data.id,
                                    bikeId: res.data.id,
                                    error: null,
                                });
                            }
                        }
                    ).catch(err => {
                        console.log(err);
                    });

                } else {
                    this.setState({
                        error: 'Nie znaleziono roweru'
                    });
                }

            }
        ).catch(err => {
            console.log(err);
        });

    };

    handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/bike/register-engraving/${this.state.bikeId}`, {},
            {
                headers: {
                    "x-auth-token": this.props.auth.token,
                }
            }
        ).then(res => {
                this.setState({
                    registered: true,
                });
            }
        ).catch(err => {
            console.log(err);
        });
    };



    render() {

        const SearchForm = (
            <Fragment>
                <div className='text-center'>
                    <p> Wprowadź kod graweru </p>
                    <div className='error-label'>
                        {this.state.error}
                    </div>
                    <div style={{marginTop: '20px'}} className='col-6 offset-3 text-center'>
                        <input name='number' type="text" className="form-control input"
                               onChange={this.handleInputChange}
                               placeholder='kod graweru'/>
                    </div>
                    <div style={{marginTop: '20px'}} className='col-12 text-center'>
                        <button type="button" className="btn btn-primary change-data"
                                onClick={this.handleFindBike}>
                            WYSZUKAJ ROWER
                        </button>
                    </div>
                </div>
            </Fragment>
        );

        const BikeForm = (
            <Fragment>
                <div className='owner-background'>
                    <form onSubmit={this.handleSubmit}>
                        <div className='row image'>
                            <img src={this.bikeImagePath(this.state.bike)} alt="rower"/>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Numer graweru:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.engraving_number}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Numer certyfikatu:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.certificate_number}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Numer seryjny:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.serial_number}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Numer fabryczny:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.manufacturing_number}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Inny numer:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.custom_number}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Marka roweru:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.brandName}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Model roweru:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.modelName}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Rodzaj napędu:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.typeDriveName}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Średnica tylnego koła:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.back_wheel_diameter}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Średnica przedniego koła:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.front_wheel_diameter}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Wartość roweru:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.value}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Nazwa jednostki policji:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.policeStationName}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Miejscowość rejestracji:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.register_city}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Opis obiektu:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.description}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-md-6'>Uwagi:</div>
                            <div className='col-8 col-md-6'>
                                {this.state.bike.note}
                            </div>
                        </div>
                        <div className='col-12 text-center' style={{marginTop: '20px'}}>
                            <button type="submit" className="btn btn-primary change-data">REJESTRUJ
                                ROWER
                            </button>
                        </div>
                    </form>
                </div>
            </Fragment>
        );

        const RegisteredForm = (
            <Fragment>
                <div className='text-center'>
                    <p> Rower został wstępnie zarejestrowany. Zgłoś się do najbliższej jednostki Policji w
                        celu dokończenia rejestracji.</p>
                </div>
            </Fragment>
        );



        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2" id="search">
                        <h3 className="text-center">
                            Rejestracja roweru z grawerem
                        </h3>
                        {this.state.registered ? RegisteredForm : <Fragment> {this.state.found ? BikeForm : SearchForm}</Fragment>
                        }
                    </div>
                </div>
            </BasicLayout>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(BikeRegisterEngraving);