import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import PropTypes from 'prop-types';
import policeLogo from './assets/images/logo.svg';
import wiberooLogo from './assets/images/wiberoo_white2.png';
import './assets/css/style.scss';
import TopMenu from "../../partials/TopMenu";
import Footer from "../../partials/Footer";
import NewsList from "../../partials/NewsList";
import BikeForm from "../../partials/BikeForm/BikeForm";
import {connect} from "react-redux";
import {authUser, logout} from "../../../actions/AuthActions";
import {getSettings} from "../../../actions/SettingsActions";
import pkg from "../../../../package.json"

class Home extends Component {

    componentDidMount() {
        this.props.getSettings();
        if(this.props.auth.isAuthenticated) {
            this.props.authUser();
        }
    }

    static propTypes = {
        authUser: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        getSettings: PropTypes.func.isRequired,
    };

    menuCollapse = () => {
        document.querySelector('#sidebar').classList.add('active');
        document.querySelector('.overlay').classList.add('active');
        document.querySelector('a').setAttribute('aria-expanded', 'false');
    };
    render() {
        return (
            <div>
                <CookieConsent
                    buttonText="Rozumiem"
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                >
                    Strona używa plików cookies, aby ułatwić Internautom korzystanie z naszych stron www oraz do celów statystycznych. Jeśli nie blokujesz tych plików, to zgadzasz się na ich użycie oraz zapisanie w pamięci Twojego komputera lub innego urządzenia. Pamiętaj, że możesz samodzielnie zmienić ustawienia przeglądarki tak, aby zablokować zapisywanie plików cookies. Więcej informacji znajdziesz
                    w <Link to="/policy">Polityce prywatności</Link>. Dodatkowo, korzystanie z naszej witryny oznacza akceptację przez Państwa klauzuli przetwarzania danych osobowych udostępnionych drogą elektroniczną. Szczegóły w dziale <Link to="/rodo">Obowiązek informacyjny Rodo</Link>
                </CookieConsent>
                <div className="overlay"></div>
                <div className="container">
                    <div className="body-wrapper container">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 home-search">
                                <div className="version">
                                    {pkg.version}
                                </div>
                                <div onClick={this.menuCollapse} className="menu-collapse">
                                    <em className="fa fa-align-right"></em>
                                </div>
                                <div className="home-search-background">
                                    <h2 className="wrappers text-center">Wielkopolski Bezpieczny Rower</h2>
                                    <img src={wiberooLogo} className="center-bike" alt="Rower"/>
                                </div>
                                <div className="search-form">
                                    <div className="row">
                                        <div className="col-12 col-md-8 offset-md-2">
                                            <BikeForm/>
                                            <div className="row text-center">
                                                <div className="col-md-12 register-cta-wrapper">
                                                    <p>Chcesz dodać rower do bazy?</p>
                                                    <Link to="/rower-rejestracja">
                                                        <strong>Zarejestruj rower</strong>
                                                    </Link>
                                                    <div className="center">
                                                        <img src={policeLogo} width={42} alt="Logo"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <TopMenu/>
                                <NewsList apiUrl={'/messages?isNewest=true'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    settings: state.settings.settingsList,
});

export default connect(mapStateToProps, {authUser, logout, getSettings})(Home);
