import React from 'react';
import {withRouter} from 'react-router-dom'

class BikeForm extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            bikeId: null,
            type: "1",
            number: '',
            bikeFound: false,
            numberLabel: ''
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {type, number} = this.state;
        if (this._isMounted === true) {
            fetch(`${process.env.REACT_APP_API_URL}/bike/search?type=${type}&number=${number}`)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            bikeFound: !!result.id,
                            bikeId: result.id
                        });
                        this.props.history.push({
                            'pathname': '/rower-wynik-wyszukiwania',
                            state: this.state
                        });
                    },
                    (error) => {
                        this.setState({
                            bikeFound: false,
                        });
                        this.props.history.push({
                            'pathname': '/rower-wynik-wyszukiwania',
                            state: this.state
                        });
                    }
                );
        }
    };

    handleInputChange = (e) => {
        e.preventDefault();
        if (this._isMounted === true) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    };

    handleInputTypeChange = (e) => {
        e.preventDefault();
        if (this._isMounted === true) {
            let typeOption = e.nativeEvent.target.selectedIndex;
            this.setState({
                numberLabel: e.nativeEvent.target[typeOption].text
            })
        }
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row bike-form'>
                    <div className="col-12 col-md-6 bike-form-select">
                        <select id="form_type" name="type"
                                onChange={(e) => {
                                    this.handleInputTypeChange(e);
                                    this.handleInputChange(e)
                                }}
                                className="form-control">
                            <option value="1" defaultValue={1}>Numer grawerunku</option>
                            <option value="2">Numer certyfikatu</option>
                            <option value="3">Numer seryjny</option>
                            <option value="4">Numer fabryczny</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-6 bike-form-input">
                        <input type="text"
                               id="form_number"
                               name="number"
                               onChange={this.handleInputChange}
                               required="required"
                               className="form-control"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 search-bike-btn">
                        <button type="submit" id="form_search"
                                className="btn-primary btn">Sprawdź rower
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

export default withRouter(BikeForm);
