import { combineReducers } from 'redux';
import bikeReducer from './BikeReducer';
import ErrorReducer from './ErrorReducer';
import AuthReducer from './AuthReducer'
import SettingsReducer from './SettingsReducer'

export default combineReducers({
    bike: bikeReducer,
    error: ErrorReducer,
    auth: AuthReducer,
    settings: SettingsReducer
})
