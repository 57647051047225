import React, {Component} from 'react';
import BasicLayout from "../../layouts/BasicLayout";
import {Link} from "react-router-dom";
import './assets/css/style.scss'
import {connect} from "react-redux";
import PropTypes from 'prop-types';

class BikeRegister extends Component {
    static propTypes = {
        auth: PropTypes.object,
    };

    render() {
        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2" id="search">
                        <div className="row">
                            <div className='container text-center'>
                                <h3 className='text-center'>
                                    Rejestracja roweru
                                </h3>

                                {this.props.auth.isAuthenticated ? <div className='btn-group-vertical'>

                                    <Link className="btn btn-primary button-margin btn-register" to={{
                                        pathname: '/rower-rejestracja-nowy',
                                    }}>
                                        REJESTRACJA NOWEGO ROWERU
                                    </Link>

                                    <Link className="btn btn-primary button-margin btn-register" to={{
                                        pathname: '/rower-rejestracja-grawer',
                                    }}>
                                        REJESTRACJA GRAWEREM
                                    </Link>


                                    <Link className="btn btn-primary button-margin btn-register" to={{
                                        pathname: '/rower-rejestracja-kodem',
                                    }}>
                                        REJESTRACJA KODEM
                                    </Link>
                                </div> : <p className='error-label'>Aby zarejestrować rower należy się zalogować</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </BasicLayout>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(BikeRegister);
