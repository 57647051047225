import React, {Component, Fragment} from 'react';
import axios from "axios";
import BasicLayout from "../../layouts/BasicLayout";
import './assets/css/bike-edit-style.scss';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {confirmAlert} from 'react-confirm-alert';
import {connect} from "react-redux";

class BikeEditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.location.state.id,
            status: null,
            statuses: [],
            error: null,
            description: null,
            internal_code: null,
        }
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/bike/single/${this.state.id}`, {
            headers: {
                'x-auth-token': this.props.auth.token
            }
        })
            .then(res => {
                    this.setState({
                        status: res.data.status_id,
                    });
                    this.updateDescription(this.state.status)
                }
            ).catch(err => {
            console.log(err);
        });

        axios.get(`${process.env.REACT_APP_API_URL}/dictionary/bike-statuses`).then(res => {
                this.setState({
                    statuses: res.data,
                });
            }
        ).catch(err => {
            console.log(err);
        });
    }

    updateDescription(id) {
        axios.get(`${process.env.REACT_APP_API_URL}/dictionary/status-desc/${id}`,).then(res => {
                if (res) {
                    this.setState({
                        description: res.data.description,
                        internal_code: res.data.internal_code
                    })
                }
            }
        ).catch(err => {
            console.log(err);
        });
    }

    cancelEdit = () => {
        this.props.history.push({
            pathname: `/rower/${this.state.id}`,
            state: {isAuthenticated: true}
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        confirmAlert({
            title: 'Potwierdzenie',
            message: 'Czy potwierdzasz zmianę statusu?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => {
                        axios.post(`${process.env.REACT_APP_API_URL}/bike/edit`, {
                            id: this.state.id,
                            status_id: this.state.status,
                        }).then(res => {
                                if (res.data.error) {
                                    NotificationManager.error('Wystąpił błąd podczas zapisu.', 'error');
                                    this.setState({
                                        error: res.data.error,
                                    });
                                } else {

                                    this.props.history.push({
                                        pathname: `/rower/${this.state.id}`,
                                        state: {notificationMsg: 'Zapisano zmiany.'}
                                    });

                                    this.setState({
                                        error: null,
                                    });
                                }
                            }
                        ).catch(err => {
                            console.log(err);
                        });
                    }
                },
                {
                    label: 'Nie',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    handleInputChange = (e) => {
        e.preventDefault();
        this.updateDescription(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        let statuses = this.state.statuses;
        let statusesItems = statuses.map((status) =>
            <option value={status.id} key={status.id}>{status.name}</option>
        );

        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2" id="search">
                        <div className="row">
                            <div className='container'>
                                <h3 className="text-center">
                                    Zmiana statusu roweru
                                </h3>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="owner-background">
                                        <div className='row'>
                                            <div className='col-4 col-md-6 first'>Nowy status roweru
                                            </div>
                                            <div className='col-8 col-md-6 second'>
                                                <Fragment>
                                                    <select
                                                        name="status"
                                                        className="form-control"
                                                        value={this.state.status}
                                                        onChange={this.handleInputChange}
                                                    >
                                                        {statusesItems}
                                                    </select>
                                                    {this.state.error ?
                                                        <div
                                                            className='error-label'> {this.state.error.status} </div> : null}

                                                </Fragment>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div style={this.state.internal_code === 'IPI' ? {color: 'red'}:{}}>
                                            {this.state.description}
                                        </div>
                                    </div>
                                    <div style={{marginTop: '20px'}} className='col-12 text-center'>
                                        <button type="button" className="btn btn-primary change-data"
                                                onClick={this.cancelEdit}>Wróć bez dokonywania zmian
                                        </button>
                                        <button type="submit" className="btn btn-primary change-data">
                                            Zapisz dane
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </BasicLayout>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(BikeEditForm);
