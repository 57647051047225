import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BasicLayout from '../../layouts/BasicLayout';
import {getBikeList} from "../../../actions/BikeActions";
import {clearErrors} from "../../../actions/ErrorActions";
import defaultRower from "./assets/images/rower.png"

const BikeList = (props) => {

    useEffect(() => {
        props.clearErrors();
        if (props.auth.user) {
            props.getBikeList();
        }
    }, [props.auth.user]);

    const bikeImagePath = (bike) => {
        let path;
        if (bike.attachments[0]) {
            path = '/uploads/' + bike.attachments[0].file_path;
        } else {
            path = defaultRower;
        }

        return path;
    };

    let result;
    if (props.error.id === 'NEWS_ERROR') {
        result = <div className='alert-danger'>{this.props.error.msg.msg}</div>;
    } else {
        result = props.bike.items.map(singleBike => (
            <Link key={`bike_id_${singleBike.id}`} to={{
                pathname: `/rower/${singleBike.id}`,
                state: {isAuthenticated: true}
            }} className="link">
                <div key={singleBike.id} className="list-bikes">
                    <div>
                        <div className='row'>

                            <div className='col-4 col-md-2 col-xl-1'>
                                <img src={bikeImagePath(singleBike)} alt="rower"/>
                            </div>


                            <div className='col-8 col-md-10 description'>


                                <div className='row'>
                                    <div className='col-md-3 offset-md-0 wrap second'>
                                        <p>{singleBike.brandName}</p>
                                        <p>{singleBike.modelName}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3 offset-md-0 first'>GRAWER:</div>
                                    <div className='col-md-3 second'>{singleBike.engraving_number}</div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3 offset-md-0 first'>CERTYFIKAT:</div>
                                    <div className='col-md-3 second'>{singleBike.certificate_number}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        ))
    }
    return (
        <BasicLayout>
            <div className='continer' id="search">
                <h3 className=" text-center">
                    Moje rowery
                </h3>
                <div className="col col-md-10 offset-md-1">
                    {result}
                </div>
            </div>
        </BasicLayout>
    )
};

BikeList.propTypes = {
    getBikeList: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    error: PropTypes.object,
};

const mapStateToProps = (state) => ({
    bike: state.bike,
    error: state.error,
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {getBikeList, clearErrors}
)(BikeList);
