export const SETTINGS_FETCH = 'SETTINGS_FETCH';
export const GET_NEWS = 'GET_NEWS';
export const GET_SINGLE_NEWS = 'GET_SINGLE_NEWS';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const AUTH_KEY_SUCCESS = 'AUTH_KEY_SUCCESS';
export const AUTH_KEY_FAIL = 'AUTH_KEY_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_KEY_RESET = 'AUTH_KEY_RESET';

export const GET_SINGLE_BIKE = 'GET_SINGLE_BIKE';
export const BIKE_LOADING = 'BIKE_LOADING';
export const GET_BIKES = 'GET_BIKES';
