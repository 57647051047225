import React, {Component, Fragment} from 'react';
import axios from "axios";
import {CSVLink} from "react-csv";
import {connect} from "react-redux";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {confirmAlert} from "react-confirm-alert";
import {logout} from "../../../actions/AuthActions";
import PropTypes from 'prop-types';

class Institution extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            editEnabled: false,
            inputEnabled: false,
            error: null,
            anonymizeEnabled: false,
            key: null
        }
    };

    static propTypes = {
        logout: PropTypes.func.isRequired,
    };

    headersCSV = () => [
        {label: 'Imię', key: 'contact_first_name'},
        {label: 'Nazwisko', key: 'contact_last_name'},
        {label: 'Email', key: 'contact_email'},
        {label: 'Numer telefonu', key: 'contact_phone_number'},
        {label: 'Adres', key: 'address'},
        {label: 'Kod pocztowy', key: 'post_code'},
        {label: 'Miasto', key: 'city'},
        {label: 'Opis', key: 'contact_note'},
        {label: 'NIP', key: 'NIP'},
        {label: 'Nazwa firmy', key: 'name'},
    ];

    editButtonHandler = (e) => {
        e.preventDefault();

        if (!this.state.editEnabled) {

            this.setState({
                editEnabled: true,
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.state.inputEnabled) {
            axios.post(`${process.env.REACT_APP_API_URL}/owner/send-verify-key`, this.state.user).then(res => {
                    if (res.data) {
                        NotificationManager.info('Na podany numer telefonu został wysłany kod weryfikacyjny', '');
                    }
                }
            ).catch(err => {
                console.log(err);
            });

            this.setState({
                inputEnabled: true,
            });
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/owner/edit?type=institution&key=${this.state.user.key}`, this.state.user).then(res => {
                    if (res.data.error) {
                        this.setState({
                            error: res.data.error,
                        });
                    } else {
                        NotificationManager.success('Zapisano zmiany. Otrzymasz Informację potwierdzającą zmianę danych na email i telefon', '');
                        this.setState({
                            editEnabled: false,
                            inputEnabled: false,
                            error: null,
                        });
                    }
                }
            ).catch(err => {
                this.setState({
                    error: err.response.data.error,
                });
            });
        }
    };

    handleInputChange = (e) => {
        e.preventDefault();

        const name = e.target.name;
        const value = e.target.value;

        let {user} = this.state;
        user[name] = value;

        this.setState({
            user: user
        });

    };

    getHistory = (e) => {
        e.preventDefault();

        axios.get(`${process.env.REACT_APP_API_URL}/owner/history`,
            {
                responseType: 'blob',
                headers: {
                    "x-auth-token": this.props.auth.token,
                }
            }).then(res => {
                if (res.data) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'historia_uzytkownika.csv');
                    document.body.appendChild(link);
                    link.click();
                }
            }
        ).catch(err => {
            console.log(err);
        });
    };

    cancelEdit = () => {
        this.setState({
            editEnabled: false,
            inputEnabled: false,
        });
    };

    handleAnonymise = (e) => {
        e.preventDefault();

        if (!this.state.inputEnabled) {

            axios.post(`${process.env.REACT_APP_API_URL}/owner/send-verify-key`, this.state.user).then(res => {
                    if (res.data) {
                        NotificationManager.info('Na podany numer telefonu został wysłany kod weryfikacyjny', '');
                    }
                }
            ).catch(err => {
                console.log(err);
            });

            this.setState({
                inputEnabled: true,
                anonymizeEnabled: true
            });
        } else {
            confirmAlert({
                title: 'Potwierdzenie anonimizacji',
                message: 'Czy potwierdzasz anonimizację? Twoje dane zostaną zanonimizowanie i zostaniesz wylogowany.',
                buttons: [
                    {
                        label: 'Tak',
                        onClick: () => {
                            axios.post(`${process.env.REACT_APP_API_URL}/owner/anonymize?key=${this.state.user.key}`, {}, {
                                headers: {
                                    "x-auth-token": this.props.auth.token,
                                }
                            }).then(res => {
                                    if (res.data.error) {
                                        this.setState({
                                            error: res.data.error,
                                        });
                                    } else {
                                        NotificationManager.success('Zapisano zmiany. Otrzymasz Informację potwierdzającą zmianę danych na email i telefon', '');
                                        this.setState({
                                            editEnabled: false,
                                            inputEnabled: false,
                                            error: null,
                                        });
                                        this.props.logout();
                                    }
                                }
                            ).catch(err => {
                                console.log(err);
                            });
                        }
                    },
                    {
                        label: 'Nie',
                        onClick: () => {
                        }
                    }
                ]
            });
        }
    };

    render() {
        return (
            <div className='container'>
                <h3 className="text-center">
                    Instytucja
                </h3>
                <p>
                    Twoje dane osobowe są przetwarzane przez Komendę Wojewódzką Policji w Poznaniu w zakresie opisanym
                    Regulaminem i Polityką Prywatności
                </p>
                <form onSubmit={this.handleSubmit}>
                    <div className="owner-background">
                        <div className='text-center'>
                            <div className='text-center'>Dane osoby kontaktowej</div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-4 col-md-6 first'>Imię</div>
                            <div className='col-8 col-md-6 second'>
                                {this.state.editEnabled ?
                                    <Fragment>
                                        <input name='contact_first_name' type='text' className='form-control'
                                               value={this.state.user.contact_first_name}
                                               onChange={this.handleInputChange}/>

                                        {this.state.error ?
                                            <div
                                                className='error-label'> {this.state.error.contact_first_name} </div> : null}

                                    </Fragment> : this.state.user.contact_first_name}
                            </div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-4 col-md-6 first'>Nazwisko</div>
                            <div className='col-8 col-md-6 second'>
                                {this.state.editEnabled ?
                                    <Fragment>
                                        <input name='contact_last_name' type='text' className='form-control'
                                               value={this.state.user.contact_last_name}
                                               onChange={this.handleInputChange}/>

                                        {this.state.error ?
                                            <div
                                                className='error-label'> {this.state.error.contact_last_name} </div> : null}

                                    </Fragment> : this.state.user.contact_last_name}
                            </div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-4 col-md-6 first'>Email</div>
                            <div className='col-8 col-md-6 second'>{this.state.editEnabled ?
                                <Fragment>
                                    <input name='contact_email' type='text' className='form-control'
                                           value={this.state.user.contact_email} onChange={this.handleInputChange}/>

                                    {this.state.error ?
                                        <div className='error-label'> {this.state.error.contact_email} </div> : null}

                                </Fragment> : this.state.user.contact_email}</div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-4 col-md-6 first'>Numer telefonu</div>
                            <div className='col-8 col-md-6 second'>
                                {this.state.editEnabled ?
                                    <Fragment>
                                        <input name='contact_phone_number' type='text' className='form-control'
                                               value={this.state.user.contact_phone_number}
                                               onChange={this.handleInputChange}/>

                                        {this.state.error ?
                                            <div
                                                className='error-label'> {this.state.error.contact_phone_number} </div> : null}

                                    </Fragment> : this.state.user.contact_phone_number}
                            </div>
                        </div>
                        <hr/>
                        <div className='text-center'>
                            <div className='text-center'>Dane firmy</div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-4 col-md-6 first'>Nazwa firmy</div>
                            <div className='col-8 col-md-6 second'>
                                {this.state.editEnabled ?
                                    <Fragment>
                                        <input name='name' type='text' className='form-control'
                                               value={this.state.user.name} onChange={this.handleInputChange}/>

                                        {this.state.error ?
                                            <div className='error-label'> {this.state.error.name} </div> : null}

                                    </Fragment> : this.state.user.name}
                            </div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-4 col-md-6 first'>NIP</div>
                            <div className='col-8 col-md-6 second'>
                                {this.state.user.NIP}
                            </div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-4 col-md-6 first'>Adres</div>
                            <div className='col-8 col-md-6 second'>
                                {this.state.editEnabled ?
                                    <Fragment>
                                        <input name='address' type='text' className='form-control'
                                               value={this.state.user.address} onChange={this.handleInputChange}/>

                                        {this.state.error ?
                                            <div className='error-label'> {this.state.error.address} </div> : null}

                                    </Fragment> : this.state.user.address}
                            </div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-4 col-md-6 first'>Kod pocztowy</div>
                            <div className='col-8 col-md-6 second'>
                                {this.state.editEnabled ?
                                    <Fragment>
                                        <input name='post_code' type='text' className='form-control'
                                               value={this.state.user.post_code} onChange={this.handleInputChange}/>

                                        {this.state.error ?
                                            <div className='error-label'> {this.state.error.post_code} </div> : null}

                                    </Fragment> : this.state.user.post_code}
                            </div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-4 col-md-6 first'>Miasto</div>
                            <div className='col-8 col-md-6 second'>
                                {this.state.editEnabled ?
                                    <Fragment>
                                        <input name='city' type='text' className='form-control'
                                               value={this.state.user.city} onChange={this.handleInputChange}/>

                                        {this.state.error ?
                                            <div className='error-label'> {this.state.error.city} </div> : null}

                                    </Fragment> : this.state.user.city}
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <div style={{marginTop: '20px'}} className='col-6 offset-3 text-center'>

                            {this.state.error ?
                                <div className='error-label'> {this.state.error.key} </div> : null}

                            {this.state.inputEnabled ?
                                <input name='key' type="text" className="form-control input"
                                       onChange={this.handleInputChange}
                                       placeholder='kod autoryzujący'/> : null}
                        </div>
                        <div style={{marginTop: '20px'}} className='col-12 text-center'>
                            {this.state.editEnabled ?
                                <p>Otrzymasz infromację potwierdzającą zmianę danych na email i telefon</p> : null}
                        </div>
                        <div style={{marginTop: '20px'}} className='col-12 text-center'>
                            {this.state.editEnabled || this.state.anonymizeEnabled ? <Fragment>
                                    <button type="button" className="btn btn-primary change-data"
                                            onClick={this.cancelEdit}>Wróć bez dokonywania zmian
                                    </button>
                                    {this.state.editEnabled ?
                                        <button type="submit" className="btn btn-primary change-data">Zapisz
                                            dane</button> : null}
                                    {this.state.anonymizeEnabled ? <button type="button" className="btn btn-warning"
                                                                           onClick={this.handleAnonymise}>Usuń konto
                                        (Anonimizacja)
                                    </button> : null}
                                </Fragment> : <Fragment>
                                    <button type="button" onClick={this.editButtonHandler}
                                            className="btn btn-primary change-data">Edytuj dane
                                    </button>
                                    <CSVLink className="btn btn-primary change-data" filename={"dane-uzytkownika.csv"}
                                             data={[this.state.user]} headers={this.headersCSV()}>Pobieranie
                                        danych</CSVLink>
                                    <button type="button" className="btn btn-primary change-data"
                                            onClick={this.getHistory}>Historia danych
                                    </button>
                                    <button type="button" className="btn btn-warning"
                                            onClick={this.handleAnonymise}>Usuń konto (Anonimizacja)
                                    </button>
                                </Fragment>}
                        </div>
                    </div>
                </form>
                <NotificationContainer/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {logout})(Institution);
