import axios from "axios";
import {SETTINGS_FETCH} from "./types";

export const getSettings = login => (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_URL}/settings`)
        .then(res => {
            dispatch({
                type: SETTINGS_FETCH,
                payload: res.data
            })
        }).catch(err => {
        console.log(err);
    })
};