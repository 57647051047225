import React from 'react';
import ReactDOM from 'react-dom';
import {Route, BrowserRouter as Router} from 'react-router-dom'

import Home from './components/pages/Home/Home';
import App from './components/App';
import BikeSearch from './components/pages/BikeSearch/BikeSearch';
import News from './components/pages/News/News';
import AboutService from './components/pages/AboutService/AboutService';
import Policy from './components/pages/Policy/Policy';
import Regulations from './components/pages/Regulations/Regulations';
import Help from './components/pages/Help/Help';
import Rodo from './components/pages/Rodo/Rodo';
import Contact from './components/pages/Contact/Contact';
import BikeSearchResult from './components/pages/BikeSearchResult/BikeSearchResult';
import SingleNews from "./components/pages/SingleNews/SingleNews";
import SingleBike from "./components/pages/Bike/SingleBike";
import Login from "./components/pages/Auth/Login";
import BikeList from "./components/pages/Bike/BikeList";
import Owner from "./components/pages/Owner/Owner";
import BikeEditForm from "./components/pages/Bike/BikeEditForm";
import BikeChangeStatusForm from "./components/pages/Bike/BikeChangeStatusForm";
import BikeChangeOwnerForm from "./components/pages/Bike/BikeChangeOwnerForm";
import BikeRegister from "./components/pages/BikeRegister/BikeRegister";
import BikeReRegister from "./components/pages/BikeRegister/BikeReRegister";
import BikeRegisterNew from "./components/pages/BikeRegister/BikeRegisterNew";
import BikeAuthorization from "./components/pages/Auth/BikeAuthorization";
import BikeRegisterEngraving from "./components/pages/BikeRegister/BikeRegisterEngraving";

import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import {store, persistor} from './store';

const routing = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <div>
                    <Route path="/rower-szukaj" component={BikeSearch}/>
                    <Route path="/wiadomości" component={News}/>
                    <Route path="/informacje-o-stronie" component={AboutService}/>
                    <Route path="/rodo" component={Rodo}/>
                    <Route path="/polityka-prywatności" component={Policy}/>
                    <Route path="/regulamin" component={Regulations}/>
                    <Route path="/pomoc" component={Help}/>
                    <Route path="/kontakt" component={Contact}/>
                    <Route path="/rower-wynik-wyszukiwania" component={BikeSearchResult}/>
                    <Route path="/wiadomość/:id" component={SingleNews}/>
                    <Route path="/zaloguj" component={Login}/>
                    <Route path="/rower/:id" component={SingleBike}/>
                    <Route path="/lista-rowerów" component={BikeList}/>
                    <Route path="/właściciel" component={Owner}/>
                    <Route path="/rower-edycja" component={BikeEditForm}/>
                    <Route path="/rower-edycja-statusu" component={BikeChangeStatusForm}/>
                    <Route path="/rower-autoryzacja" component={BikeAuthorization}/>
                    <Route path="/rower-zmiana-właściciela" component={BikeChangeOwnerForm}/>
                    <Route path="/rower-rejestracja" component={BikeRegister}/>
                    <Route path="/rower-rejestracja-kodem" component={BikeReRegister}/>
                    <Route path="/rower-rejestracja-nowy" component={BikeRegisterNew}/>
                    <Route path="/rower-rejestracja-grawer" component={BikeRegisterEngraving}/>
                    <Route path="/strona-główna" component={Home}/>
                    <Route path="/" component={App}/>
                </div>
            </Router>
        </PersistGate>
    </Provider>
);
ReactDOM.render(routing, document.getElementById('root'));

