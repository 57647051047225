import {
    SETTINGS_FETCH
} from "../actions/types";

const initalState = {
   settingsList: null
};

export default function(state = initalState, action){
    switch(action.type) {
        case SETTINGS_FETCH:
            return {
                settingsList: action.payload
            };
        default:
            return state;

    }
}