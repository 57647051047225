import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGOUT_SUCCESS,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    AUTH_KEY_SUCCESS,
    AUTH_KEY_FAIL,
    AUTH_KEY_RESET
} from '../actions/types';

const initalState = {
    token: null,
    isAuthenticated: null,
    isLoading: false,
    isCodeSend: false,
    user: null
};

export default function(state = initalState, action){
    switch(action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                isCodeSend: false,
                user: action.payload
            };
        case AUTH_KEY_SUCCESS:
            return {
                ...state,
                isCodeSend: true,
                user: action.payload
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isLoading: false,
                isCodeSend: false
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isCodeSend: true
            };
        case AUTH_ERROR:
        case AUTH_KEY_FAIL:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isLoading: false,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
            };
        case AUTH_KEY_RESET:
            return {
                ...state,
                isCodeSend: false,
            };
        default:
            return state;

    }
}