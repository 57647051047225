import React from 'react';
import MenuItems from './MenuItems';
import MenuBottomItems from './MenuBottomItems';
import NavbarWibero from './assets/images/wiberoo-logo.svg';
import Bike from './assets/images/bike.svg';
import Police from './assets/images/police.svg';

import Logo from './assets/images/logo.svg';

function TopMenu() {

    const dismissMenu = () => {
        document.querySelector('#sidebar').classList.remove('active');
        document.querySelector('.overlay').classList.remove('active');
    };

    const menuCollapse = () => {
        document.querySelector('#sidebar').classList.add('active');
        document.querySelector('.overlay').classList.add('active');
        document.querySelector('a').setAttribute('aria-expanded', 'false');
    };

    return (
        <div>
            <nav className="navbar navbar-expand-sm navbar-white">
                <div className="navbar-mobile-logo">
                    <div className="navbar-collapse">
                        <img src={Police} alt="police-logo"/>
                        <img src={NavbarWibero} alt="logo"/>
                        <div onClick={menuCollapse} className="menu-collapse">
                            <em className="fa fa-align-right"></em>
                        </div>
                    </div>
                </div>
                <div className="collapse navbar-collapse" id="home-navbar">
                    <div className="navbar-logo-left">
                        <img src={Bike} alt="bike-logo"/>
                    </div>
                    <div className="navbar-logo-left2">
                        <div className="navbar-logo-left2-img">
                            <img src={NavbarWibero} alt="logo"/>
                        </div>
                        <div className="navbar-logo-left2-text">
                            Wielkopolski Bezpieczny Rower
                        </div>
                    </div>
                    <MenuItems/>
                    <div className="navbar-logo-right">
                        <img src={Police} alt="police-logo"/>
                    </div>
                </div>
            </nav>

            <nav id="sidebar">
                <div className="sidebar-header">
                    <a href="#" onClick={dismissMenu} className="dismiss-menu">
                        <em className="fas fa-times"></em>
                    </a>
                </div>
                <div className="sidebar-body">
                    <div className="main-menu">
                        <MenuItems/>
                    </div>
                    <div className="bottom-items">
                        <MenuBottomItems/>
                    </div>
                    <div className="menu-footer">
                        {/*<img src={EuropeanFundsImage} alt="Unia Europejska"/>*/}
                        <img src={Logo} alt="Logo"/>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default TopMenu;
