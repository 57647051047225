import React, {Component} from "react";
import Modal from 'react-bootstrap/Modal'
import axios from "axios";
import {ReCaptcha} from 'react-recaptcha-google'
import {connect} from 'react-redux';


class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            email: null,
            title: null,
            description: null,
            error: false,
            msg: null,
            isRecaptchaVerified: false,
            allAgreements: false,
            processingPersonalDataAgreement: false,
            transferingPersonalDataAgreement: false,
            receivingInformationElectrionicallyAgreement: false,

        };
    }

    handleClose = (e) => {
        this.setState({show: false});
    };

    handleShow = (e) => {
        e.preventDefault();
        this.setState({show: true});
    };

    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    sendContactForm = (e) => {
        e.preventDefault();

        // walidacja
        this.setState({error: false});

        if (!this.state.title || this.state.title.length < 5) {
            this.setState({
                msg: 'Wpisz w tytuł przynajmniej 5 znaków',
                error: true
            })
            return;
        }

        if (!this.state.description || this.state.description.length < 15) {
            this.setState({
                msg: 'Wpisz w opis przynajmniej 15 znaków',
                error: true
            })
            return;
        }

        if (!this.state.processingPersonalDataAgreement
            ||
            !this.state.transferingPersonalDataAgreement
            ||
            !this.state.receivingInformationElectrionicallyAgreement) {
            this.setState({
                msg: 'Przed wysłaniem wiadomości musisz zaznaczyć wszystkie zgody',
                error: true
            })
            return;
        }

        if (!this.props.settings.recaptchaEnabled || (!this.state.isRecaptchaVerified && parseInt(this.props.settings.recaptchaEnabled)) === 1) {
            this.setState({
                isRecaptchaVerified: false,
                msg: 'Proszę wypełnij recaptchę',
                error: true
            })
            return;
        }

        // wysyłanie kontaktu
        if (!this.state.error){
            this.setState({
                msg: 'Proszę czekać wysyłam',
                error: true
            })
            axios.post(`${process.env.REACT_APP_API_URL}/contact-form`, {
                email: this.state.email,
                title: this.state.title,
                description: this.state.description,
            }).then(res => {
                this.setState({
                    msg: null,
                    error: false,
                    isRecaptchaVerified: false,
                    processingPersonalDataAgreement: false,
                    transferingPersonalDataAgreement: false,
                    receivingInformationElectrionicallyAgreement: false,
                    allAgreement: false
                })
                this.handleClose();
            });
        }

    };

    verifyRecaptchaCallback = (response) => {
        if(response) {
            this.setState({
                isRecaptchaVerified: true,
                msg: null,
            })
        }
    };

    onLoadCallback = (onload) => {
    };

    onAllAgreementsChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            processingPersonalDataAgreement: value,
            transferingPersonalDataAgreement: value,
            receivingInformationElectrionicallyAgreement: value,
            allAgreement: value
        })
    };

    onAgreementChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    render() {
        return (
            <div>
                <div className='contact-button btn btn-primary' onClick={this.handleShow} data-toogle="tooltip" data-placement="top" title="Formularz kontaktowy">
                    <em className="fas fa-envelope-square contact-form-sign"></em>
                </div>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Formularz kontaktowy</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.sendContactForm}>
                            <input name='email' type="email" className="form-control input"
                                   onChange={this.handleInputChange} placeholder='E-mail zgłaszającego' onInvalid={this.set} required/>
                            <input name='title' type="text" className="form-control input"
                                   onChange={this.handleInputChange} placeholder='Tytuł'/>
                            <textarea name='description' rows='4' className="form-control input"
                                      onChange={this.handleInputChange} placeholder='Opis'/>
                            <br/>
                            <div class="small-gray-text">Przedtem musisz zaakceptować zgody na przetwarzanie danych osobowych oraz zgody na przesyłanie informacji przez wskazanie przez ciebie medium w zakresie przesyłania odpowiedzi na zgłoszenie</div>
                            <label class="small-gray-text">
                                <input name="allAgreements"
                                       type="checkbox"
                                       onChange={this.onAllAgreementsChange}
                                       checked={this.state.allAgreement}
                                /> Świadomie zaznaczam wszystkie poniższe zgody:
                            </label>
                            <label class="small-gray-text">
                                <input name="processingPersonalDataAgreement"
                                       type="checkbox"
                                       checked={this.state.processingPersonalDataAgreement}
                                       onChange={this.onAgreementChange}
                                /> Oświadczam że wyrażam zgodę na przetwarzanie moich danych osobowych w zakresie niezbędnym do realizacji założeń programu Wiberoo
                            </label>
                            <label class="small-gray-text">
                                <input name="transferingPersonalDataAgreement"
                                       type="checkbox"
                                       checked={this.state.transferingPersonalDataAgreement}
                                       onChange={this.onAgreementChange}
                                /> Oświadczam, że wyrażam zgodę na przekazywania moich danych osobowych innym administratorom w zakresie realizacji podobnych programów prewencyjnych i w tym samym celu
                            </label>
                            <label class="small-gray-text">
                                <input name="receivingInformationElectrionicallyAgreement"
                                       type="checkbox"
                                       checked={this.state.receivingInformationElectrionicallyAgreement}
                                       onChange={this.onAgreementChange}
                                /> Oświadczam że wyrażam zgodę na otrzymywanie wiadomości drogą elektroniczną poprzez email
                            </label>
                            <div className='recaptcha'>
                                {this.props.settings && this.props.settings.recaptchaKey && parseInt(this.props.settings.recaptchaEnabled) === 1 ?
                                    <ReCaptcha
                                        sitekey={this.props.settings.recaptchaKey}
                                        size="normal"
                                        render="explicit"
                                        verifyCallback={this.verifyRecaptchaCallback}
                                        onloadCallback={this.onLoadCallback}
                                        hl={"pl"}
                                    /> : null}
                            </div>
                            {
                                this.state.error
                                    ?
                                <div className='error-label'>{this.state.msg}</div>
                                    :
                                null
                            }
                            <button type="submit"
                                    className="btn btn-primary col-md-12 d-flex justify-content-center"> Wyślij
                            </button>

                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    error: state.error,
    isCodeSend: state.auth.isCodeSend,
    settings: state.settings.settingsList,
    isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps) (ContactForm);
