import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import BasicLayout from "../../layouts/BasicLayout";
import {connect} from "react-redux";

class BikeSearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.location.state,
            allAgreement: false,
            rodoData: false,
            rodoDataSms: false,
            rodoDataEmail: false,
            rodoDataAdmin: false,
            getDataBySmsAgreement: false,
            getDataByEmailAgreement: false
        }
    }

    handleAllCheckBox = (e) => {
        let model = this.state;
        if (model[e.target.name] === false) {
            model[e.target.name] = true;
            this.setState({
                rodoData: true,
                rodoDataSms: true,
                rodoDataEmail: true,
                rodoDataAdmin: true,
                getDataBySmsAgreement: true,
                getDataByEmailAgreement: true
            });
        } else if (model[e.target.name] === true) {
            model[e.target.name] = false;
            this.setState({
                rodoData: false,
                rodoDataSms: false,
                rodoDataEmail: false,
                rodoDataAdmin: false,
                getDataBySmsAgreement: false,
                getDataByEmailAgreement: false
            });
        }

    };

    handleCheckbox = (e) => {
        let model = this.state;
        if (e.target.type === 'checkbox') {
            if (model[e.target.name] === false) {
                model[e.target.name] = true;

            } else if (model[e.target.name] === true) {
                model[e.target.name] = false;
            } else {
                model[e.target.name] = true;
            }
        } else {
            model[e.target.name] = e.target.value;
        }

        this.setState({
           model: model
        });

    };

    render() {
        let result;
        let link;
        if (this.state.bikeFound === false) {
            result = <h2>Brak wyników</h2>;
            link = <Link className="btn btn-primary d-none" to="/login">Więcej</Link>;
        } else {
            result = <h2>Rower jest w bazie</h2>;
            if (this.props.auth.isAuthenticated) {
                link = <Link
                    className="btn btn-primary"
                    to={{
                        pathname: `/rower/${this.state.bikeId}`,
                        state: {isAuthenticated: true}
                    }}>Więcej
                </Link>;
            } else {
                link = <Link
                    className="btn btn-primary"
                    to={{
                        pathname: `/rower-autoryzacja`,
                        bikeId: this.state.bikeId,
                    }}>Więcej
                </Link>;
            }
        }

        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-4" id="bike-search-results">
                        {result}
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="input-group mb-3">
                                    <label>
                                        {this.state.numberLabel}
                                        <input type="text"
                                               readOnly
                                               id="form_number"
                                               name="number"
                                               placeholder={this.state.number}
                                               required="required"
                                               className="form-control"
                                        />
                                    </label>
                                </div>
                                {this.state.bikeFound ? <Fragment>
                                    <div className="row small-gray-text aggrements">
                                        <div className="col-12">
                                            {(this.state.rodoAgreement && this.state.smsAgreement && this.state.emailAgreement) ? 'Dziękujemy za zaznaczenie zgód' :
                                                'Przedtem musisz zaakceptować zgody na przetwarzanie danych osobowych oraz zgody na przesyłanie infomracji przez wskazanie przez ciebie ' +
                                                'medium w zakresie przesyłanie jednorazowego kodu weryfikacyjnego'}
                                        </div>
                                    </div>
                                    <div className="row small-gray-text">
                                        <div className="col-12">
                                            <input type='checkbox' name='allAgreement'
                                                   value={this.state.allAgreement}
                                                   onChange={this.handleAllCheckBox}
                                                   checked={this.state.allAgreement}/>
                                            Świadomie zaznaczam wszystkie poniższe zgody
                                        </div>
                                        <div className="col-12">
                                            <input type='checkbox' name='rodoData'
                                                   value={this.state.rodoData}
                                                   onChange={this.handleCheckbox}
                                                   checked={this.state.rodoData}/>
                                            Oświadczam że wyrażam zgodę na przetwarzanie moich danych osobowych w
                                            zakresie niezbędnym do realizacji założeń programu Wiberoo:
                                        </div>
                                        <div className="col-11 offset-1">
                                            <input type='checkbox' name='rodoDataEmail'
                                                   value={this.state.rodoDataEmail}
                                                   onChange={this.handleCheckbox}
                                                   checked={this.state.rodoDataEmail}/>
                                            Adres email
                                        </div>
                                        <div className="col-11 offset-1">
                                            <input type='checkbox' name='rodoDataSms'
                                                   value={this.state.rodoDataSms}
                                                   onChange={this.handleCheckbox}
                                                   checked={this.state.rodoDataSms}/>
                                            Numer telefonu
                                        </div>
                                        <div className="col-12">
                                            <input type='checkbox' name='rodoDataAdmin'
                                                   value={this.state.rodoDataAdmin}
                                                   onChange={this.handleCheckbox}
                                                   checked={this.state.rodoDataAdmin}/>
                                            Oświadczam, że wyrażam zgodę na przekazywania moich danych osobowych
                                            innym administratorom w zakresie realizacji podobnych programów
                                            prewencyjnych i w tym samym celu
                                        </div>
                                        <div className="col-12">
                                            <input type='checkbox' name='getDataByEmailAgreement'
                                                   value={this.state.getDataByEmailAgreement}
                                                   onChange={this.handleCheckbox}
                                                   checked={this.state.getDataByEmailAgreement}/>
                                            Oświadczam że wyrażam zgodę na otrzymywanie wiadomości drogą elektroniczną
                                            poprzez email
                                        </div>
                                        <div className="col-12">
                                            <input type='checkbox' name='getDataBySmsAgreement'
                                                   value={this.state.getDataBySmsAgreement}
                                                   onChange={this.handleCheckbox}
                                                   checked={this.state.getDataBySmsAgreement}/>
                                            Oświadczam że wyrażam zgodę na otrzymywanie wiadomości drogą elektroniczną
                                            poprzez SMS
                                        </div>
                                    </div>
                                    <div className="row search-bike-btn">
                                        <div className="col-md-12 d-flex justify-content-center">
                                            {(this.state.rodoData && this.state.rodoDataSms && this.state.rodoDataEmail
                                            && this.state.rodoDataAdmin && this.state.getDataBySmsAgreement && this.state.getDataByEmailAgreement) ? link : null}
                                        </div>
                                    </div>
                                    <p className="small-gray-text">
                                        {this.state.bikeFound ?
                                            <span>Kliknij więcej, aby wyświetlić informacje o rowerze {this.props.auth.isAuthenticated ? null : ' - będziesz musiał podać dane kontaktowe'}</span> : null}
                                    </p>
                                </Fragment> : null}

                                <p className="small-gray-text">
                                    <span>Chcesz sprawdzić inny rower?</span>
                                    <div className="search-bike-btn">
                                        <Link className="btn btn-primary" to="/rower-szukaj"><span
                                            className="text-uppercase font-weight-bold">Wyszukaj ponownie</span></Link>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </BasicLayout>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(BikeSearchResult);
