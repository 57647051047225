import React, {Fragment} from 'react';
import {getSingleBikeById} from "../../../actions/BikeActions";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './assets/css/style.scss';
import BasicLayout from "../../layouts/BasicLayout";
import {Link} from "react-router-dom";
import axios from "axios";
import Notification from "../../partials/Notification";
import {NotificationContainer} from "react-notifications";

class SingleBike extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showCertificateDownloadButton: false,
            isAuthenticated: this.props.location.state ? this.props.location.state.isAuthenticated : false
        };
    }

    onImgClick(e) {
        const currentMainElement = document.querySelector('#current-main-element');
        const galleryElement = document.querySelectorAll('.gallery-elements img');
        const opacity = 0.6;

        galleryElement[0].style.opacity = opacity;
        galleryElement.forEach(img => (img.style.opacity = 1));

        currentMainElement.src = e.target.src;

        currentMainElement.classList.add('fade-in');
        currentMainElement.classList.add('gallery-main-element');

        setTimeout(() => currentMainElement.classList.remove('fade-in'), 500);
        e.target.style.opacity = opacity;
    }

    componentDidMount() {
        let bikeId = this.props.match.params.id;
        if (bikeId) {
            this.props.getSingleBikeById(bikeId);
        }

        if (this.props.location.state) {
            Notification(this.props.location.state.notificationMsg, 'success');
            this.props.location.state.notificationMsg = false;
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.settings) {
            this.setState({
                showCertificateDownloadButton: parseInt(this.props.settings.showGenerateCertificateButton)
            });
        }
    }

    getCertificate = (e) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_URL}/bike/certificate/${this.props.bike.id}`, {responseType: 'blob'}).then(res => {
                if (res) {
                    const url = window.URL.createObjectURL(new Blob([res.data]
                        , {type: "application/pdf"}));
                    let link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'certificate.pdf');
                    document.body.appendChild(link);
                    link.click();
                }
            }
        ).catch(err => {
            console.log(err);
        });
    };

    render() {

        if (!this.state.isAuthenticated) {
            return (
                <BasicLayout>
                    <div className="row page-margin">
                        <div className="col-10 offset-1 offset-xl-3 col-xl-6 not-authenticated">
                            <h1 className="">
                                Nie zostałeś prawidłowo zautoryzowany
                            </h1>
                        </div>
                    </div>
                </BasicLayout>
            )
        }

        const {
            id,
            certificate_number,
            engraving_number,
            custom_number,
            serial_number,
            manufacturing_number,
            brandName,
            description,
            modelName,
            policeStationName,
            statusName,
            register_city,
            typeDriveName,
            backWheel,
            frontWheel,
            attachments,
            isOperate,
            statusInternalCode
        } = this.props.bike;

        if (parseInt(this.props.match.params.id) !== parseInt(id)) {
            return null;
        }

        let gallery = '';
        if (typeof attachments !== 'undefined' && attachments.length > 0) {
            gallery = (
                <Fragment>
                    <div className="gallery-container">
                        <div className="gallery-main-element">
                            <img id="current-main-element" src={`/uploads/${attachments[0].file_path}`} alt="rower"/>
                        </div>
                        <div className="gallery-elements">
                            {attachments.map(attachment => (
                                <img onClick={this.onImgClick} src={`/uploads/${attachment.file_path}`} alt="rower"/>
                            ))}
                        </div>
                    </div>
                </Fragment>
            )
        }

        return (
            <BasicLayout>
                <div className="row page-margin">
                    <div className="col-md-6 col-sm-8 singlePageContainer">
                        <div>
                            {gallery}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-10 offset-sm-0 container bike-content">
                        <h2>Szczegółowe dane roweru</h2>
                        <p>Numery</p>
                        {statusInternalCode && statusInternalCode.toString() === 'IS' ? null :
                            <div>
                                <div className='row'>
                                    <div className='col-6 first'>GRAWER:</div>
                                    <div className='col-6 second'>{engraving_number}</div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 first'>CERTYFIKAT:</div>
                                    <div className='col-6 second'>{certificate_number}</div>
                                </div>
                            </div>}
                        <div className='row'>
                            <div className='col-6 first'>SERIA:</div>
                            <div className='col-6 second'>{serial_number}</div>
                        </div>
                        <div className='row'>
                            <div className='col-6 first'>FABRYCZNY:</div>
                            <div className='col-6 second'>{manufacturing_number}</div>
                        </div>
                        <div className='row'>
                            <div className='col-6 first'>INNY:</div>
                            <div className='col-6 second'>{custom_number}</div>
                        </div>
                        <p>Informacje</p>
                        <div className='row'>
                            <div className='col-6 first'>MARKA:</div>
                            <div className='col-6 second'>{brandName}</div>
                        </div>
                        <div className='row'>
                            <div className='col-6 first'>MODEL:</div>
                            <div className='col-6 second'>{modelName}</div>
                        </div>
                        <div className='row'>
                            <div className='col-6 first'>NAPĘD:</div>
                            <div className='col-6 second'>{typeDriveName}</div>
                        </div>
                        <div className='row'>
                            <div className='col-6 first'>ŚREDNICA KÓŁ:</div>
                            <div className='col-6 second'>{backWheel}/{frontWheel}</div>
                        </div>
                        <div className='row'>
                            <div className='col-6 first'>STATUS:</div>
                            <div className='col-6 second'>{statusName}</div>
                        </div>
                        <p>Opis</p>
                        <div className='row wrap'>
                            <div className='col-12 first'>{description}</div>
                        </div>
                        <p>Inne</p>
                        <div className='row'>
                            <div className='col-6 first'>JEDNOSTKA POLICJI:</div>
                            <div className='col-6 second'>{policeStationName}</div>
                        </div>
                        <div className='row'>
                            <div className='col-6 first'>MIEJSCE REJESTRACJI:</div>
                            <div className='col-6 second'>{register_city}</div>
                        </div>
                    </div>
                </div>
                <hr style={{padding: '10px'}}/>
                <div className='row bike-button-group'>
                    {isOperate ? <div className='btn-group'>
                        <Link className="btn btn-primary action-btns action-btn1" to={{
                            pathname: '/rower-edycja',
                            state: {
                                id
                            }
                        }}> ZMIENIAM DANE </Link>

                        <Link className="btn btn-primary action-btns action-btn2" to={{
                            pathname: '/rower-edycja-statusu',
                            state: {
                                id
                            }
                        }}>ZGŁASZAM UTRATĘ
                        </Link>

                        {this.state.showCertificateDownloadButton === 1 ?
                            <Link onClick={this.getCertificate} className="btn btn-primary action-btns action-btn3"
                                  to={{
                                      pathname: '/rower-edycja',
                                      state: {
                                          id
                                      }
                                  }}>POBIERAM CERTYFIKAT</Link> : null}

                        <Link className="btn btn-primary action-btns action-btn4" to={{
                            pathname: '/rower-zmiana-właściciela',
                            state: {
                                id
                            }
                        }}>ZMIENIAM WŁAŚCICIELA</Link>

                    </div> : null}
                </div>
                <NotificationContainer/>
            </BasicLayout>
        );
    }
}

SingleBike.propTypes = {
    getSingleBikeById: PropTypes.func,
    bike: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    bike: state.bike.item,
    settings: state.settings.settingsList,
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {getSingleBikeById}
)(SingleBike);
