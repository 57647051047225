import React from 'react';
import BasicLayout from '../layouts/BasicLayout';

export default class StaticLayout extends React.Component {
    render() {
        return (
            <BasicLayout>
                <div className="static-page">
                    {this.props.children}
                </div>
            </BasicLayout>
        );
    }
}