import axios from "axios";
import BasicLayout from "../../layouts/BasicLayout";
import './assets/css/bike-edit-style.scss';
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

class BikeChangeOwnerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.location.state.id,
            checkbox: false,
            submitEnabled: false,
            key: null,
            error: null,
            bike: false,
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.state.submitEnabled) {

            axios.post(`${process.env.REACT_APP_API_URL}/bike/send-verify-key-change-owner?token=${this.props.auth.token}`)
                .then(res => {
                        if (res.data) {
                            NotificationManager.info('Na podany numer telefonu został wysłany sms z kodem weryfikacyjnym', '');
                            this.setState({
                                submitEnabled: true
                            })
                        }
                    }
                )
                .catch(err => {
                    console.log(err);
                });
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/bike/change-owner?id=${this.state.id}&key=${this.state.key}&token=${this.props.auth.token}`)
                .then(res => {
                        if (res.data.error) {
                            this.setState({
                                error: res.data.error,
                            })
                        } else if (res.data) {
                            this.setState({
                                bike: res.data.bike,
                                submitEnabled: false,
                                checkbox: false,
                            })
                        }
                    }
                )
                .catch(err => {
                    console.log(err);
                });
        }
    };

    handleInputChange = (e) => {
        let model = this.state;
        if (e.target.type === 'checkbox') {
            if (model[e.target.name] === false) {
                model[e.target.name] = true;
            } else if (model[e.target.name] === true) {
                model[e.target.name] = false;
            } else {
                model[e.target.name] = true;
            }
        } else {
            model[e.target.name] = e.target.value;
        }
        this.setState({
            model: model
        });
    };

    render() {
        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2" id="search">
                        <div className="row">
                            <div className='container text-center'>
                                <h3 className='text-center'>
                                    Zmiana właściciela
                                </h3>
                                {this.state.bike ?
                                    <p> Rower o certyfikacie {this.state.bike} zmienił status na zbyty.</p> :
                                    <Fragment>
                                        <p> Informuję, że zbyłem rower i nie jestem już w jego posiadaniu.
                                            Zostaniesz poproszony o podanie kodu jednorazowego.
                                            Po zatwierdzeniu otrzymasz kod przerejestrowania który możesz przekazać nowemu
                                            właścicielowi. </p>
                                        <form onSubmit={this.handleSubmit}>
                                            <input type='checkbox' name='checkbox' value={this.state.submitEnabled}
                                                   onChange={this.handleInputChange}/>
                                            Oświadczam, że w/w dane są prawdziwe, że byłem właścicielem i przeszedł on w
                                            posiadanie nowego właściciela.
                                            <div style={{marginTop: '20px'}} className='col-6 offset-3 text-center'>

                                                {this.state.error ?
                                                    <div className='error-label'> {this.state.error} </div> : null}

                                                {this.state.submitEnabled ?
                                                    <input name='key' type="text" className="form-control input"
                                                           onChange={this.handleInputChange}
                                                           placeholder='kod autoryzujący'/> : null}
                                            </div>
                                            <div style={{marginTop: '20px'}} className='col-12 text-center'>
                                                {this.state.checkbox ?
                                                    <button type="submit" name="submitEnabled" className="btn btn-primary change-data">ZMIEŃ
                                                        WŁAŚCICIELA
                                                    </button> : null}
                                            </div>
                                        </form>
                                    </Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </BasicLayout>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(BikeChangeOwnerForm);
