import React, {Component} from 'react';
import {connect} from "react-redux";
import {authUser, logout} from "../actions/AuthActions";
import {getSettings} from "../actions/SettingsActions";
import { loadReCaptcha } from 'react-recaptcha-google'

class App extends Component {

    componentDidMount() {
        loadReCaptcha();
    }

    protectedRoutes = ['/lista-rowerów', '/właściciel', '/rower-edycja', '/rower-edycja-statusu', '/rower-zmiana-właściciela', '/rower-rejestracja',
        '/rower-rejestracja-kodem', '/rower-rejestracja-nowy', '/rower-rejestracja-grawer'];

    render() {

        if (this.props.location.pathname === '/') {
            this.props.history.push('/strona-główna')
        }

        if (this.protectedRoutes.indexOf(this.props.location.pathname) !== -1) {
            if (! this.props.auth.isAuthenticated) {
                this.props.history.push('/zaloguj')
            }
        }

        return (
            <div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {authUser, logout, getSettings})(App);
