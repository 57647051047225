import React, {Component} from 'react';
import BasicLayout from "../../layouts/BasicLayout";
import axios from "axios";
import {connect} from "react-redux";

class BikeReRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitEnabled: true,
            key: null,
            error: null,
            bike: null
        }
    }

    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/bike/re-register?key=${this.state.key}&token=${this.props.auth.token}`)
            .then(res => {
                    if (res.data.error) {
                        this.setState({
                            error: res.data.error,
                        })
                    } else if (res.data.bike) {
                        this.setState({
                            bike: res.data.bike,
                            submitEnabled: false,
                            error: null,
                        })
                    }
                }
            )
            .catch(err => {
                console.log(err);
            });
    };

    render() {
        return (
            <BasicLayout>
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2" id="search">
                        <div className="row">
                            <div className='container text-center'>
                                <h3 className='text-center'>
                                    Rejestracja roweru kodem przerejestrowania
                                </h3>
                                {this.state.bike ?
                                    <p> Rower o certyfikacie {this.state.bike} został wstępnie zarejestrowany.
                                        Należy udać się na najbliższy posterunek Policji w celu dokończenia rejestracji. </p> :
                                    <p> wprowadź kod przerejestrowania </p>}
                                <form onSubmit={this.handleSubmit}>
                                    <div style={{marginTop: '20px'}} className='col-6 offset-3 text-center'>
                                        {this.state.error ?
                                            <div className='error-label'> {this.state.error} </div> : null}

                                        <input name='key' type="text" className="form-control input"
                                               onChange={this.handleInputChange}
                                               placeholder='kod przerejestrowania'/>
                                    </div>
                                    <div style={{marginTop: '20px'}} className='col-12 text-center'>
                                        {this.state.submitEnabled ?
                                            <button type="submit" className="btn btn-primary change-data">PRZEREJESTRUJ
                                                ROWER
                                            </button> : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </BasicLayout>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(BikeReRegister);